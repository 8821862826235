
export const suppliers = [
    "Asher & Son Ltd T/A Davin Foods - F",
    "Aubrey Allen Ltd",
    "Belazu Ingredient Company",
    "Bibendum Wine Ltd",
    "Brakes Ambient",
    "Brakes Ambient D1 D2",
    "Brakes Chilled D1 D2",
    "Brakes Chilled Ltd",
    "Brakes Costa",
    "Brakes Frozen Foodservice Ltd",
    "Bunzl Catering Supplies",
    "Classic Fine Foods UK Ltd",
    "Coors National Sales EDI",
    "CostCutter",
    "DDC Foods Ltd",
    "Deli Station",
    "Enotria Winecellars Limited",
    "Enterprise Foods",
    "Euro Food Brands Ltd",
    "Fairfax Meadow",
    "Fat Boys Ltd t/a Simply Lunch",
    "First Choice Produce Ltd",
    "Fulfil Limited",
    "Harvey & Brockless Limited",
    "IMS of Smithfield Ltd",
    "Jill M Jones",
    "King Asia Foods Ltd",
    "Lake District Farmers",
    "MSK Ingredients Ltd",
    "Natures Choice Catering Greengrocer",
    "Neals Yard Dairy (WSale)",
    "Nestle Starbucks WPS",
    "Noje Ltd/Hej Coffee",
    "Paul Rhodes London Limited",
    "Peppercorn Foods Limited",
    "Planglow Ltd",
    "Reynolds Catering Supplies Ltd",
    "Seafood Holdings t/a Direct Seafood",
    "Solstice Limited",
    "Street Eats Food Ltd",
    "Sweet Ideas Ltd",
    "Tanpopo Japanese Food Ltd",
    "The Bread Factory",
    "The United Fresh Consortium Ltd",
    "Town & Country Fine Foods",
    "UCC",
    "Vegetarian Express",
    "Wild Harvest"
  ]